import Cabecalho from "../componentes/cabecalho";

function Entidade () {
    
    return (
        <div>
            <div>
                <Cabecalho/>
            </div>

        </div>
    ) 
    

}

export default Entidade;