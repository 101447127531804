import Cabecalho from "../componentes/cabecalho";

function AreaEntidade() {
  return (
    <div>
      <Cabecalho />
    </div>
  );
}

export default AreaEntidade;
